import { default as edit_45typeRinv3yX5OOMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/edit-type.vue?macro=true";
import { default as _91id_930IyZgRztSsMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/[id].vue?macro=true";
import { default as create4uyO9lhl9PMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/create.vue?macro=true";
import { default as index6yOAxAz1NTMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/index.vue?macro=true";
import { default as engine4U0TG2xtJBMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine.vue?macro=true";
import { default as index1bqpNo1XUAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/index.vue?macro=true";
import { default as enginesvdFgHWVUzbMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/engines.vue?macro=true";
import { default as indexYGsUwOfinFMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/index.vue?macro=true";
import { default as indexYP32lJIy1ZMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue?macro=true";
import { default as _91id_93ez4u4pIaWEMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id].vue?macro=true";
import { default as index729BbakXKDMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/index.vue?macro=true";
import { default as policy_45editorOKqyrJpQFUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor.vue?macro=true";
import { default as indexjUnCcqhhJCMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/index.vue?macro=true";
import { default as editHrgOKeqoU5Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue?macro=true";
import { default as addNumjeOsjQaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue?macro=true";
import { default as createhyYqWGGbBhMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/create.vue?macro=true";
import { default as indexFfGnUjTNhoMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/index.vue?macro=true";
import { default as _91id_93RPMHLTZTTrMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/[id].vue?macro=true";
import { default as create6ubKN5nGFmMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/create.vue?macro=true";
import { default as indexFG6eaOpZ5xMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/index.vue?macro=true";
import { default as providerhVjAmTn6aDMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider.vue?macro=true";
import { default as region9ooyKCoElaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/region.vue?macro=true";
import { default as source_45documentMRLROgQsJnMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-document.vue?macro=true";
import { default as source_45type_45level_451ClB0ZOYMB5Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-1.vue?macro=true";
import { default as source_45type_45level_452melyIBBg7WMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-2.vue?macro=true";
import { default as _91id_934Z7YAhpfp0Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/[id].vue?macro=true";
import { default as createADukAaBuwjMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/create.vue?macro=true";
import { default as indexYtFRziNZt9Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/index.vue?macro=true";
import { default as topicAje3aTCMeQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic.vue?macro=true";
import { default as auth_45callback852wOlvwczMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginj4HtcCjkEiMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusPp8tL2HSytMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: edit_45typeRinv3yX5OOMeta?.name ?? "edit-type",
    path: edit_45typeRinv3yX5OOMeta?.path ?? "/edit-type",
    meta: edit_45typeRinv3yX5OOMeta || {},
    alias: edit_45typeRinv3yX5OOMeta?.alias || [],
    redirect: edit_45typeRinv3yX5OOMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/edit-type.vue").then(m => m.default || m)
  },
  {
    name: engine4U0TG2xtJBMeta?.name ?? undefined,
    path: engine4U0TG2xtJBMeta?.path ?? "/engine",
    meta: engine4U0TG2xtJBMeta || {},
    alias: engine4U0TG2xtJBMeta?.alias || [],
    redirect: engine4U0TG2xtJBMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_930IyZgRztSsMeta?.name ?? "engine-id",
    path: _91id_930IyZgRztSsMeta?.path ?? ":id()",
    meta: _91id_930IyZgRztSsMeta || {},
    alias: _91id_930IyZgRztSsMeta?.alias || [],
    redirect: _91id_930IyZgRztSsMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/[id].vue").then(m => m.default || m)
  },
  {
    name: create4uyO9lhl9PMeta?.name ?? "engine-create",
    path: create4uyO9lhl9PMeta?.path ?? "create",
    meta: create4uyO9lhl9PMeta || {},
    alias: create4uyO9lhl9PMeta?.alias || [],
    redirect: create4uyO9lhl9PMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/create.vue").then(m => m.default || m)
  },
  {
    name: index6yOAxAz1NTMeta?.name ?? "engine",
    path: index6yOAxAz1NTMeta?.path ?? "",
    meta: index6yOAxAz1NTMeta || {},
    alias: index6yOAxAz1NTMeta?.alias || [],
    redirect: index6yOAxAz1NTMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index1bqpNo1XUAMeta?.name ?? "index",
    path: index1bqpNo1XUAMeta?.path ?? "/",
    meta: index1bqpNo1XUAMeta || {},
    alias: index1bqpNo1XUAMeta?.alias || [],
    redirect: index1bqpNo1XUAMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/index.vue").then(m => m.default || m)
  },
  {
    name: policy_45editorOKqyrJpQFUMeta?.name ?? undefined,
    path: policy_45editorOKqyrJpQFUMeta?.path ?? "/policy-editor",
    meta: policy_45editorOKqyrJpQFUMeta || {},
    alias: policy_45editorOKqyrJpQFUMeta?.alias || [],
    redirect: policy_45editorOKqyrJpQFUMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93ez4u4pIaWEMeta?.name ?? undefined,
    path: _91id_93ez4u4pIaWEMeta?.path ?? ":id()",
    meta: _91id_93ez4u4pIaWEMeta || {},
    alias: _91id_93ez4u4pIaWEMeta?.alias || [],
    redirect: _91id_93ez4u4pIaWEMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id].vue").then(m => m.default || m),
    children: [
  {
    name: enginesvdFgHWVUzbMeta?.name ?? "policy-editor-id-engines",
    path: enginesvdFgHWVUzbMeta?.path ?? "engines",
    meta: enginesvdFgHWVUzbMeta || {},
    alias: enginesvdFgHWVUzbMeta?.alias || [],
    redirect: enginesvdFgHWVUzbMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/engines.vue").then(m => m.default || m)
  },
  {
    name: indexYGsUwOfinFMeta?.name ?? "policy-editor-id",
    path: indexYGsUwOfinFMeta?.path ?? "",
    meta: indexYGsUwOfinFMeta || {},
    alias: indexYGsUwOfinFMeta?.alias || [],
    redirect: indexYGsUwOfinFMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexYP32lJIy1ZMeta?.name ?? "policy-editor-id-reference-document",
    path: indexYP32lJIy1ZMeta?.path ?? "reference-document",
    meta: indexYP32lJIy1ZMeta || {},
    alias: indexYP32lJIy1ZMeta?.alias || [],
    redirect: indexYP32lJIy1ZMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index729BbakXKDMeta?.name ?? "policy-editor",
    path: index729BbakXKDMeta?.path ?? "",
    meta: index729BbakXKDMeta || {},
    alias: index729BbakXKDMeta?.alias || [],
    redirect: index729BbakXKDMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexjUnCcqhhJCMeta?.name ?? "policy-id",
    path: indexjUnCcqhhJCMeta?.path ?? "/policy/:id()",
    meta: indexjUnCcqhhJCMeta || {},
    alias: indexjUnCcqhhJCMeta?.alias || [],
    redirect: indexjUnCcqhhJCMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: editHrgOKeqoU5Meta?.name ?? "policy-id-reference-document-docId-edit",
    path: editHrgOKeqoU5Meta?.path ?? "/policy/:id()/reference-document/:docId()/edit",
    meta: editHrgOKeqoU5Meta || {},
    alias: editHrgOKeqoU5Meta?.alias || [],
    redirect: editHrgOKeqoU5Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue").then(m => m.default || m)
  },
  {
    name: addNumjeOsjQaMeta?.name ?? "policy-id-reference-document-add",
    path: addNumjeOsjQaMeta?.path ?? "/policy/:id()/reference-document/add",
    meta: addNumjeOsjQaMeta || {},
    alias: addNumjeOsjQaMeta?.alias || [],
    redirect: addNumjeOsjQaMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue").then(m => m.default || m)
  },
  {
    name: createhyYqWGGbBhMeta?.name ?? "policy-create",
    path: createhyYqWGGbBhMeta?.path ?? "/policy/create",
    meta: createhyYqWGGbBhMeta || {},
    alias: createhyYqWGGbBhMeta?.alias || [],
    redirect: createhyYqWGGbBhMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/create.vue").then(m => m.default || m)
  },
  {
    name: indexFfGnUjTNhoMeta?.name ?? "policy",
    path: indexFfGnUjTNhoMeta?.path ?? "/policy",
    meta: indexFfGnUjTNhoMeta || {},
    alias: indexFfGnUjTNhoMeta?.alias || [],
    redirect: indexFfGnUjTNhoMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/index.vue").then(m => m.default || m)
  },
  {
    name: providerhVjAmTn6aDMeta?.name ?? undefined,
    path: providerhVjAmTn6aDMeta?.path ?? "/provider",
    meta: providerhVjAmTn6aDMeta || {},
    alias: providerhVjAmTn6aDMeta?.alias || [],
    redirect: providerhVjAmTn6aDMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93RPMHLTZTTrMeta?.name ?? "provider-id",
    path: _91id_93RPMHLTZTTrMeta?.path ?? ":id()",
    meta: _91id_93RPMHLTZTTrMeta || {},
    alias: _91id_93RPMHLTZTTrMeta?.alias || [],
    redirect: _91id_93RPMHLTZTTrMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/[id].vue").then(m => m.default || m)
  },
  {
    name: create6ubKN5nGFmMeta?.name ?? "provider-create",
    path: create6ubKN5nGFmMeta?.path ?? "create",
    meta: create6ubKN5nGFmMeta || {},
    alias: create6ubKN5nGFmMeta?.alias || [],
    redirect: create6ubKN5nGFmMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/create.vue").then(m => m.default || m)
  },
  {
    name: indexFG6eaOpZ5xMeta?.name ?? "provider",
    path: indexFG6eaOpZ5xMeta?.path ?? "",
    meta: indexFG6eaOpZ5xMeta || {},
    alias: indexFG6eaOpZ5xMeta?.alias || [],
    redirect: indexFG6eaOpZ5xMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: region9ooyKCoElaMeta?.name ?? "region",
    path: region9ooyKCoElaMeta?.path ?? "/region",
    meta: region9ooyKCoElaMeta || {},
    alias: region9ooyKCoElaMeta?.alias || [],
    redirect: region9ooyKCoElaMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/region.vue").then(m => m.default || m)
  },
  {
    name: source_45documentMRLROgQsJnMeta?.name ?? "source-document",
    path: source_45documentMRLROgQsJnMeta?.path ?? "/source-document",
    meta: source_45documentMRLROgQsJnMeta || {},
    alias: source_45documentMRLROgQsJnMeta?.alias || [],
    redirect: source_45documentMRLROgQsJnMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-document.vue").then(m => m.default || m)
  },
  {
    name: source_45type_45level_451ClB0ZOYMB5Meta?.name ?? "source-type-level-1",
    path: source_45type_45level_451ClB0ZOYMB5Meta?.path ?? "/source-type-level-1",
    meta: source_45type_45level_451ClB0ZOYMB5Meta || {},
    alias: source_45type_45level_451ClB0ZOYMB5Meta?.alias || [],
    redirect: source_45type_45level_451ClB0ZOYMB5Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-1.vue").then(m => m.default || m)
  },
  {
    name: source_45type_45level_452melyIBBg7WMeta?.name ?? "source-type-level-2",
    path: source_45type_45level_452melyIBBg7WMeta?.path ?? "/source-type-level-2",
    meta: source_45type_45level_452melyIBBg7WMeta || {},
    alias: source_45type_45level_452melyIBBg7WMeta?.alias || [],
    redirect: source_45type_45level_452melyIBBg7WMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-2.vue").then(m => m.default || m)
  },
  {
    name: topicAje3aTCMeQMeta?.name ?? undefined,
    path: topicAje3aTCMeQMeta?.path ?? "/topic",
    meta: topicAje3aTCMeQMeta || {},
    alias: topicAje3aTCMeQMeta?.alias || [],
    redirect: topicAje3aTCMeQMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_934Z7YAhpfp0Meta?.name ?? "topic-id",
    path: _91id_934Z7YAhpfp0Meta?.path ?? ":id()",
    meta: _91id_934Z7YAhpfp0Meta || {},
    alias: _91id_934Z7YAhpfp0Meta?.alias || [],
    redirect: _91id_934Z7YAhpfp0Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/[id].vue").then(m => m.default || m)
  },
  {
    name: createADukAaBuwjMeta?.name ?? "topic-create",
    path: createADukAaBuwjMeta?.path ?? "create",
    meta: createADukAaBuwjMeta || {},
    alias: createADukAaBuwjMeta?.alias || [],
    redirect: createADukAaBuwjMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/create.vue").then(m => m.default || m)
  },
  {
    name: indexYtFRziNZt9Meta?.name ?? "topic",
    path: indexYtFRziNZt9Meta?.path ?? "",
    meta: indexYtFRziNZt9Meta || {},
    alias: indexYtFRziNZt9Meta?.alias || [],
    redirect: indexYtFRziNZt9Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: auth_45callback852wOlvwczMeta?.name ?? "auth-callback",
    path: auth_45callback852wOlvwczMeta?.path ?? "/auth/callback",
    meta: auth_45callback852wOlvwczMeta || {},
    alias: auth_45callback852wOlvwczMeta?.alias || [],
    redirect: auth_45callback852wOlvwczMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue").then(m => m.default || m)
  },
  {
    name: auth_45loginj4HtcCjkEiMeta?.name ?? "auth-login",
    path: auth_45loginj4HtcCjkEiMeta?.path ?? "/auth/login",
    meta: auth_45loginj4HtcCjkEiMeta || {},
    alias: auth_45loginj4HtcCjkEiMeta?.alias || [],
    redirect: auth_45loginj4HtcCjkEiMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue").then(m => m.default || m)
  },
  {
    name: auth_45statusPp8tL2HSytMeta?.name ?? "auth-status",
    path: auth_45statusPp8tL2HSytMeta?.path ?? "/auth/status",
    meta: auth_45statusPp8tL2HSytMeta || {},
    alias: auth_45statusPp8tL2HSytMeta?.alias || [],
    redirect: auth_45statusPp8tL2HSytMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue").then(m => m.default || m)
  }
]